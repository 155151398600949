import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

export const FriendsPageTemplate = ({
  friends,
  helmet
}) => {
  return (
    <section className="friends-container">
      {helmet || ''}
      <ul className='friends-text-container'>
        {friends.map(friend => {
          return (
            <a key={friend.name} href={friend.link}>
              <li className='friends-text-item'>
                <h2>{friend.name}</h2>
              </li>
            </a>
          )
        })}
      </ul>
      <div className='friends-logo-container'>
        {friends.map(friend => {
          return (
            <li key={friend.name} className='friends-logo-item'>
              <a  href={friend.link}>
                <PreviewCompatibleImage
                  imageInfo={{
                    style: { maxWidth: '200px' },
                    image: friend.logo,
                    alt: `Image logo for ${friend.name}`,
                  }}
                />
              </a>
            </li>
          )
        })}
      </div>
    </section>
  )
}

FriendsPageTemplate.propTypes = {
  friends: PropTypes.array.isRequired,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const FriendsPage = ({ data }) => {
  const { markdownRemark: page } = data

  return (
    <Layout activePage="friends">
      <FriendsPageTemplate
        friends={page.frontmatter.friends}
        helmet={
          <Helmet titleTemplate="%s | Friends">
            <title>{`SM28 | Friends`}</title>
            {/*TODO SEO DESCRIPTION
            <meta
              name="description"
              content={`${page.frontmatter.description}`}
            /> */}
          </Helmet>
        }
        title={'Friends'}
      />
    </Layout>
  )
}

FriendsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
}

export default FriendsPage

export const pageQuery = graphql`
  query FriendsPage($id: String!){
    markdownRemark(id: {eq: $id}) {
      frontmatter {
        friends {
            name
            link
            logo {
                childImageSharp {
                    fluid(maxWidth: 300, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
      }
    }
  }
`
